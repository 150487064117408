import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Menus from "./components/menus";
import Loading from "./components/Loading";
import GlobalDataResolver from "./providers/GlobalDataResolver";

const Mapa = React.lazy(() => import("./routes/mapa"));
const Tabela = React.lazy(() => import("./routes/tabela"));

const App = () => {
  return (
    <React.Fragment>
      <GlobalDataResolver />
      <Menus>
        <React.Suspense fallback={<Loading />}>
          <Routes>
            <Route path={"/mapa"} element={<Mapa />} />
            <Route path={"/tabela"} element={<Tabela />} />
            <Route
              path={"*"}
              index
              element={<Navigate to={"/mapa"} replace />}
            />
          </Routes>
        </React.Suspense>
      </Menus>
    </React.Fragment>
  );
};

export default App;
