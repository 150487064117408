import create from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { Estacao } from "../../../providers/useGlobalData";

export enum ViewMode {
  CHUVA = "chuva",
  RIO = "rio",
  VENTO = "vento",
  TEMPERATURA = "temperatura",
  SENS_TERMICA = "sens_termica",
  UMIDADE = "umidade",
  PRESSAO_ATMOSFERICA = "pressao",
  // BARRAGEM = "barragem",
}

export enum ChartMode {
  RIO = "rio",
  CHUVA = "chuva",
  NONE = "none",
}

type MapData = {
  selected?: string;
  setSelected(id?: string): void;
  unsetSelected(): void;

  showPopup: boolean;
  openPopup(): void;
  closePopup(): void;

  isBarragensVisible: boolean;
  hideBarragensVisible: () => void;
  showBarragensVisible: () => void;
  toggleBarragensVisible: () => void;
  setBarragensVisible: (value: boolean) => void;

  viewMode: ViewMode;
  setViewMode(mode: ViewMode): void;

  chartMode: ChartMode;
  setChartMode(mode: ChartMode, estacao: Estacao): void;
};

const useMapData = create<MapData>((set, get) => ({
  selected: undefined,
  setSelected: (id) => {
    set({
      selected: id,
      // Limpa outros parâmetros
      chartMode: ChartMode.NONE,
    });
    if (id) get().openPopup();
  },
  unsetSelected: () => get().setSelected(),

  showPopup: true,
  // Desabilitados a pedido da DC
  openPopup: () =>
    set({
      /* showPopup: true */
    }),
  closePopup: () =>
    set({
      /* showPopup: false */
    }),

  isBarragensVisible: true,
  showBarragensVisible: () => set({ isBarragensVisible: true }),
  hideBarragensVisible: () => set({ isBarragensVisible: false }),
  toggleBarragensVisible: () =>
    set({ isBarragensVisible: !get().isBarragensVisible }),
  setBarragensVisible: (value) => set({ isBarragensVisible: value }),

  // Valores de controle do mapa:
  center: {
    lat: -27.6986,
    lng: -50.9129,
  },

  viewMode: ViewMode.CHUVA,
  setViewMode: (mode) => set({ viewMode: mode, showPopup: false }),

  chartMode: ChartMode.NONE,
  setChartMode: (mode, estacao) => {
    switch (true) {
      case mode === ChartMode.RIO && estacao.tipo === "Hidro":
        set({ chartMode: ChartMode.RIO });
        break;
      case mode === ChartMode.CHUVA &&
        ["Hidro", "Pluvio", "Meteo"].includes(estacao.tipo!):
        set({ chartMode: mode });
        break;
      default:
        set({ chartMode: undefined });
    }
  },
}));

process.env.NODE_ENV === "development" &&
  mountStoreDevtool("MapData", useMapData);

export default useMapData;
