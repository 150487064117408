import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

const useBreakpoints = () => {
  const theme = useTheme();
  //#region Breakpoints
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.down("lg"));
  const lg = useMediaQuery(theme.breakpoints.down("xl"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  // const xxl = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  //#endregion

  const breakpoints = React.useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      // xxl,
    }),
    [xs, sm, md, lg, xl /*, xxl */]
  );

  return breakpoints;
};

export default useBreakpoints;
