import { create } from "zustand";
import { persist } from "zustand/middleware";

type PaletteMode = import("@mui/material").PaletteMode;

export enum TipoEstacao {
  HIDRO = "Hidro",
  METEO = "Meteo",
  PLUVIO = "Pluvio",
  BARRAGEM = "Barragem",
}

export type Estacao = {
  nome?: string;
  tipo?: TipoEstacao;
  codigo?: string;
  last_read?: string;
  nivel_rio?: number;

  nivel_rio_historico?: Array<{ t_stamp: string; nivel: number }>;

  chuva_001h?: number;
  chuva_003h?: number;
  chuva_006h?: number;
  chuva_012h?: number;
  chuva_024h?: number;
  chuva_048h?: number;
  chuva_072h?: number;
  chuva_096h?: number;
  chuva_120h?: number;
  chuva_144h?: number;
  chuva_168h?: number;

  temp_atual?: number;
  temp_sens?: number;
  umidade?: number;

  vel_vento?: number;
  dir_vento?: number;
  pres_atmos?: number;

  localizacao?: {
    lat: number;
    lng: number;
  };

  nivel_montante?: number;
  nivel_jusante?: number;
  porc_reservatorio?: number;
  comp_abertas?: number;
  comp_fechadas?: number;
};

type GlobalDataState = {
  estacoes: Estacao[];
  paletteMode?: PaletteMode;
  svgColorFilter?: string;

  regioes?: any;

  isSidebarVisible: boolean;
  isSidebarLocked: boolean;

  mobileMode: boolean;
};
type GlobalDataActions = {
  setEstacoes: (estacoes: Estacao[]) => void;
  setPaletteMode: (mode: PaletteMode) => void;
  togglePaletteMode: () => void;

  setSvgColorFilter(filter: string): void;

  setRegioes(regioes: string): void;

  showSidebar: () => void;
  hideSidebar: () => void;
  toggleSidebar: () => void;
  lockSidebar: () => void;
  unlockSidebar: () => void;

  setMobileMode: (value: boolean) => void;
};
export type GlobalData = GlobalDataState & GlobalDataActions;

const useGlobalData = create(
  persist<GlobalData>(
    (set, get) => {
      // Preparação báse dos dados
      return {
        estacoes: [],
        setEstacoes(estacoes) {
          set({ estacoes });
        },

        paletteMode: undefined,
        setPaletteMode: (mode) => {
          // Altera o svgColorFilter baseado no modo
          let svgColorFilter = "";
          switch (mode) {
            case "light":
              svgColorFilter =
                "brightness(0) saturate(100%) invert(50%) sepia(72%) saturate(895%) hue-rotate(165deg) brightness(25%) contrast(105%)";
              break;
            case "dark":
              svgColorFilter =
                "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(85deg) brightness(104%) contrast(101%)";
              break;
          }

          set({ paletteMode: mode, svgColorFilter });
        },
        togglePaletteMode: () => {
          const { paletteMode, setPaletteMode } = get();

          setPaletteMode(paletteMode === "light" ? "dark" : "light");
          // set({
          //   paletteMode: get().paletteMode === "light" ? "dark" : "light",
          // });
        },

        svgColorFilter: undefined,
        setSvgColorFilter: (filter) => {
          set({ svgColorFilter: filter });
        },

        regioes: undefined,
        setRegioes: (regioes) => {
          set({ regioes: JSON.parse(regioes) });
        },

        isSidebarVisible: false,
        showSidebar: () => set({ isSidebarVisible: true }),
        hideSidebar: () => set({ isSidebarVisible: false }),
        toggleSidebar: () => set({ isSidebarVisible: !get().isSidebarVisible }),
        isSidebarLocked: false,
        lockSidebar: () => {
          // Some a barra
          get().hideSidebar();
          set({ isSidebarLocked: true });
        },
        unlockSidebar: () => {
          set({ isSidebarLocked: false });
        },

        mobileMode: false,
        setMobileMode: (value) => set({ mobileMode: value }),
      };
    },
    { name: "globalData", getStorage: () => localStorage }
  )
);

export default useGlobalData;
