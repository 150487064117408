import React from "react";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        minHeight: "calc(100vh - 82px)",
      }}
    >
      <Grid item component={CircularProgress} color={"primary"} size={"8rem"} />
    </Grid>
  );
};

export default Loading;
