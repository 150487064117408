import React from "react";

import { useDeviceSelectors as ogUseDeviceSelectors } from "react-device-detect";

type DeviceSelectors = {
  isAndroid: boolean;
  isBrowser: boolean;
  isChromne: boolean;
  isChromium: boolean;
  isConsole: boolean;
  isDesktop: boolean;
  isEdge: boolean;
  isEdgeChromium: boolean;
  isElectron: boolean;
  isFirefox: boolean;
  isIE: boolean;
  isIOS: boolean;
  isIOS13: boolean;
  isIPad13: boolean;
  isIPhone13: boolean;
  isIPod13: boolean;
  isLegacyEdge: boolean;
  isLegacyEngine: boolean;
  isMIUI: boolean;
  isMacOs: boolean;
  isMobile: boolean;
  isMobileOnly: boolean;
  isMobileSafari: boolean;
  isOpera: boolean;
  isSafari: boolean;
  isSamsungBrowser: boolean;
  isSmartTV: boolean;
  isTablet: boolean;
  isWearable: boolean;
  isWinPhone: boolean;
  isWindows: boolean;
  isYandex: boolean;
  mobileModel: string;
  mobileVendor: string;
  osName: string;
  osVersion: string;
};

type DeviceDataBrowser = { name: string; version: string; major: string };
type DeviceDataCPU = { architecture: string };
type DeviceDataDevice = { vendor?: string };
type DeviceDataEngine = { name: string; version: string };
type DeviceDataOS = { name: string; version: string };
type DeviceData = {
  UA: {
    getBrowser: () => DeviceDataBrowser;
    getCPU: () => DeviceDataCPU;
    getDevice: () => DeviceDataDevice;
    getEngine: () => DeviceDataEngine;
    getOS: () => DeviceDataOS;
  };
  browser: DeviceDataBrowser;
  cpu: DeviceDataCPU;
  device: DeviceDataDevice;
  engine: DeviceDataEngine;
  os: DeviceDataOS;
  setUserAgent: (userAgentString: string) => void;
  ua: string;
};

type DeviceSelectorsAndUA = [DeviceSelectors, DeviceData];

const useDeviceSelectors = () => {
  const [selectors /* , data */]: DeviceSelectorsAndUA = ogUseDeviceSelectors(
    navigator.userAgent
  );
  React.useDebugValue(selectors);

  return selectors;
};

export default useDeviceSelectors;
