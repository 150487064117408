import React from "react";

import IconButton from "@mui/material/IconButton";
import useTheme from "@mui/material/styles/useTheme";

import DarkModeIcon from "@mui/icons-material/DarkModeRounded";
import LightModeIcon from "@mui/icons-material/LightModeRounded";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

import useGlobalData from "../../../providers/useGlobalData";

const ModeToggleButton = () => {
  const togglePaletteMode = useGlobalData((d) => d.togglePaletteMode);
  const paletteMode = useGlobalData((d) => d.paletteMode);
  const svgColorFilter = useGlobalData((d) => d.svgColorFilter);
  const mobileMode = useGlobalData((d) => d.mobileMode);
  const theme = useTheme();

  const icon = React.useMemo(() => {
    const props: SvgIconProps = {
      sx: {
        [theme.breakpoints.between("md", "xl")]: {
          fontSize: "20px",
        },
        // [theme.breakpoints.up("md")]: {
        //   fontSize: "20px",
        // },
        filter: svgColorFilter,
      },
      fontSize: mobileMode ? "large" : "medium",
    };

    switch (paletteMode) {
      case "dark":
        return <DarkModeIcon {...props} />;
      case "light":
        return <LightModeIcon {...props} />;
      default:
        return null;
    }
  }, [paletteMode, theme, mobileMode, svgColorFilter]);

  // return (
  //   <Switch
  //     icon={<WbSunny fontSize={"small"} />}
  //     checkedIcon={<ModeNight fontSize={"small"} />}
  //     sx={{ filter: svgColorFilter, width: "3rem" }}
  //     value={paletteMode === "light"}
  //     onChange={togglePaletteMode}
  //     // sx={{ width: "20px" }}
  //   />
  // );

  const onClickIconButton = React.useCallback(
    () => togglePaletteMode(),
    [togglePaletteMode]
  );

  return <IconButton onClick={onClickIconButton}>{icon}</IconButton>;
};

export default ModeToggleButton;
