import React from "react";

import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";

import { Link, useLocation } from "react-router-dom";
import useGlobalData from "../../providers/useGlobalData";

const LinkButton = (props: { children: React.ReactNode; to?: string }) => {
  const theme = useTheme();
  const location = useLocation();

  const selected = React.useMemo(
    () => location.pathname === props.to,
    [location, props.to]
  );

  const paletteMode = useGlobalData((d) => d.paletteMode);

  const color = React.useMemo(() => {
    switch (paletteMode) {
      case "dark":
        return "#FFF";
      case "light":
        return "#002F4C";
      default:
        return "#000";
    }
  }, [paletteMode]);

  return (
    <Button
      component={Link}
      to={props.to ?? "/"}
      sx={{
        [theme.breakpoints.between("xs", "sm")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.between("md", "xl")]: {
          fontSize: "14px",
        },

        color: color,

        textTransform: "unset !important",
        fontWeight: selected ? 600 : 400,
        width: "100%",

        justifyContent: "flex-start",
      }}
    >
      {props.children}
    </Button>
  );
};

export default LinkButton;
