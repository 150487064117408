import React from "react";

import { useNavigate } from "react-router-dom";

import MUIAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import MenuIcon from "@mui/icons-material/TableRowsRounded";
import TableChartRoundedIcon from "@mui/icons-material/TableChartRounded";
import MapRoundedIcon from "@mui/icons-material/MapRounded";

import logo from "./logo.svg";
import LinkButton from "../LinkButton";
import ModeToggleButton from "./ModeToggleButton";
import useBreakpoints from "../../../util/useBreakpoints";
import useGlobalData from "../../../providers/useGlobalData";

//#region Compartilhados
const LogoIcon = () => {
  // const mobileMode = useGlobalData((d) => d.mobileMode);
  const theme = useTheme();
  return (
    <Grid
      item
      component={"img"}
      src={logo}
      sx={{
        maxHeight: 32,
        [theme.breakpoints.between("md", "xl")]: {
          maxHeight: 42,
        },
        [theme.breakpoints.up("xl")]: {
          maxHeight: 60,
        },

        [theme.breakpoints.down("md")]: {
          maxHeight: 60,
        },
      }}
    />
  );
};

const MenuButton = () => {
  const toggleSidebar = useGlobalData((d) => d.toggleSidebar);

  const isSidebarLocked = useGlobalData((d) => d.isSidebarLocked);
  const svgColorFilter = useGlobalData((d) => d.svgColorFilter);

  const clickButton = React.useCallback(() => {
    toggleSidebar();
  }, [toggleSidebar]);

  return (
    <IconButton
      onClick={clickButton}
      disabled={isSidebarLocked}

      // sx={{ rotate: `${isSidebarVisible ? 90 : 0}deg` }}
    >
      <MenuIcon fontSize={"large"} filter={svgColorFilter} />
    </IconButton>
  );
};
//#endregion

const Mobile = () => {
  // Sempre em baixo
  const navigate = useNavigate();
  const svgColorFilter = useGlobalData((d) => d.svgColorFilter);

  return (
    <MUIAppBar
      color={"inherit"}
      sx={{
        height: "64px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        top: "auto",
        bottom: 0,
      }}
    >
      <Container maxWidth={"xl"} disableGutters sx={{ height: "100%" }}>
        <Grid
          container
          component={Toolbar}
          columnGap={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"100%"}
        >
          <Grid item>
            <MenuButton />
          </Grid>
          <Grid item>
            <IconButton onClick={() => navigate("/tabela")}>
              <TableChartRoundedIcon
                fontSize={"large"}
                filter={svgColorFilter}
              />
            </IconButton>
          </Grid>

          <LogoIcon />

          {/* // TODO CONTINUAR AS ALTERAÇÕES VISUAIS + CORRIGIR O POPUP DO MAPA */}
          <Grid item>
            <IconButton onClick={() => navigate("/mapa")}>
              <MapRoundedIcon fontSize={"large"} filter={svgColorFilter} />
            </IconButton>
          </Grid>
          <Grid item>
            <ModeToggleButton />
          </Grid>
        </Grid>
      </Container>
    </MUIAppBar>
  );
};
const Desktop = () => {
  // Sempre no topo
  const theme = useTheme();
  const { sm } = useBreakpoints();

  return (
    <MUIAppBar
      color={"inherit"}
      sx={{
        // height: "60px",
        // [theme.breakpoints.between("md", "xl")]: {
        //   maxHeight: "56px",
        // },
        // display: "block",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth={"xl"} disableGutters>
        <Grid
          container
          component={Toolbar}
          columnGap={1}
          justifyContent={"stretch"}
          alignItems={"center"}
          height={"100%"}
          variant={"dense"}
        >
          <Grid item hidden={!sm}>
            <MenuButton />
          </Grid>

          <Grid
            item
            container
            xs
            sx={{
              [theme.breakpoints.between("md", "xl")]: {
                columnGap: 1,
              },
              columnGap: 2,
            }}
            justifyContent={!sm ? "start" : "center"}
            alignItems={"center"}
          >
            <LogoIcon />
            <Grid
              item
              component={Typography}
              fontSize={20}
              maxWidth={340}
              fontWeight={600}
              hidden={sm}
              // lineHeight={"1.4"}
              lineHeight={"1.2"}
              alignItems={"center"}
              // justifySelf
              sx={{
                [theme.breakpoints.down("xl")]: {
                  fontSize: 14,
                  maxWidth: 220,
                },
              }}
            >
              {/* Monitoramento da Rede Hidrometeorológica do Vale do Itajaí */}
              Rede Hidrometeorológica da Defesa Civil de Santa Catarina
            </Grid>
          </Grid>
          <Grid item hidden={sm}>
            <LinkButton to={"/tabela"}>Tabela</LinkButton>
          </Grid>
          <Grid item hidden={sm}>
            <LinkButton to={"/mapa"}>Mapa</LinkButton>
          </Grid>
          <Grid item>
            <ModeToggleButton />
          </Grid>
        </Grid>
      </Container>
    </MUIAppBar>
  );
};

const AppBar = () => {
  const mobileMode = useGlobalData((d) => d.mobileMode);
  if (mobileMode) return <Mobile />;
  else return <Desktop />;
};

export default AppBar;
