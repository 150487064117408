import React from "react";

import Grid from "@mui/material/Grid";

import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Radio, { RadioProps } from "@mui/material/Radio";
import Switch, { SwitchProps } from "@mui/material/Switch";
import useTheme from "@mui/material/styles/useTheme";
import Typography, { TypographyProps } from "@mui/material/Typography";

import useGlobalData from "../../../../../providers/useGlobalData";
import useMapData, { ViewMode } from "../../../providers/useMapData";

import iconBarragem from "../../../icon/icone-barragem.svg";
import iconChuva from "../../../icon/icone-chuva.svg";
import iconRio from "../../../icon/icone-rio.svg";
import iconTemp from "../../../icon/icone-temperatura.svg";
import iconSensTerm from "../../../icon/icone-sens-termica.svg";
import iconUmid from "../../../icon/icone-umidade.svg";
import iconPresAtmos from "../../../icon/icone-pres-atmos.svg";
import iconVento from "../../../icon/icone-vento.svg";
import useBreakpoints from "../../../../../util/useBreakpoints";

/** Visualização compartilhada entre o menu lateral e o overlay no mapa */
export const ControleFiltro = (props: { titleProps?: TypographyProps }) => {
  const svgColorFilter = useGlobalData((d) => d.svgColorFilter);
  const { viewMode, setViewMode } = useMapData((d) => ({
    viewMode: d.viewMode,
    setViewMode: d.setViewMode,
  }));
  const { isBarragensVisible, toggleBarragensVisible } = useMapData((d) => ({
    isBarragensVisible: d.isBarragensVisible,
    toggleBarragensVisible: d.toggleBarragensVisible,
  }));

  const theme = useTheme();

  const Controle = (props: {
    label: string;
    type: "switch" | "radio";
    icon?: string;
    iconRotation?: number;
    selectedValue?: any;
    value?: any;
    setValue?: (value: any) => void;
  }) => {
    const ControlComponent: (props: RadioProps | SwitchProps) => JSX.Element =
      React.useMemo(() => {
        switch (props.type) {
          case "radio":
            return Radio;
          case "switch":
            return Switch;
        }
      }, [props.type]);

    const theme = useTheme();
    const { xl } = useBreakpoints();

    return (
      <Grid
        item
        container
        direction={"row"}
        wrap={"nowrap"}
        alignItems={"center"}
        sx={{
          [theme.breakpoints.down("md")]: { columnGap: 1 },
          columnGap: 2,
        }}
      >
        <Grid
          item
          component={"img"}
          src={props.icon}
          maxHeight={32}
          minWidth={32}
          sx={{
            filter: svgColorFilter,
            rotate: `${props.iconRotation ?? 0}deg`,
            [theme.breakpoints.down("md")]: {
              maxHeight: "24px",
            },
            [theme.breakpoints.between("md", "xl")]: {
              maxHeight: "22px",
            },
          }}
          xs={1}
        />
        <Grid
          item
          xs
          component={Typography}
          fontWeight={"A00"}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.between("md", "xl")]: {
              fontSize: "12px",
              // textAlign: "center",
            },
            textAlign: "left",
            fontSize: "18px",
          }}
        >
          {props.label}
        </Grid>
        <Grid
          item
          component={ControlComponent}
          sx={{
            filter: Boolean(props.selectedValue === props.value)
              ? svgColorFilter
              : undefined,
          }}
          size={xl ? "medium" : "small"}
          checked={Boolean(props.selectedValue === props.value)}
          onClick={() => props.setValue!(props.value)}
        />
      </Grid>
    );
  };

  const [showOpcoes, setShowOpcoes] = React.useState(true);

  return (
    <Grid item container direction={"column"} rowGap={2} wrap={"nowrap"}>
      <Grid
        item
        onClick={() => {
          // Abre ou fecha as opções de visualização
          setShowOpcoes(!showOpcoes);
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            [theme.breakpoints.down("md")]: {
              fontSize: "17px",
            },
            [theme.breakpoints.between("md", "xl")]: {
              fontSize: "14px",
            },

            fontSize: "21px",
            fontWeight: 600,
          }}
        >
          Opções de Visualização
        </Typography>
      </Grid>

      <Grid
        item
        container
        component={Collapse}
        in={showOpcoes}
        direction={"column"}
        wrap={"nowrap"}
        sx={{
          [theme.breakpoints.between("md", "xl")]: {
            rowGap: 0.4,
          },
          rowGap: 1,
        }}
      >
        <Controle
          label={"Barragens"}
          icon={iconBarragem}
          type={"switch"}
          setValue={toggleBarragensVisible}
          value={true}
          selectedValue={isBarragensVisible}
        />
        <Grid marginTop={1} marginBottom={0.5} component={Divider} />
        <Controle
          label={"Chuva"}
          icon={iconChuva}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.CHUVA}
          selectedValue={viewMode}
        />
        <Controle
          label={"Rio"}
          icon={iconRio}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.RIO}
          selectedValue={viewMode}
        />
        <Controle
          label={"Umidade do Ar"}
          icon={iconUmid}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.UMIDADE}
          selectedValue={viewMode}
        />
        <Controle
          label={"Sensação Térmica"}
          icon={iconSensTerm}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.SENS_TERMICA}
          selectedValue={viewMode}
        />
        <Controle
          label={"Temperatura"}
          icon={iconTemp}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.TEMPERATURA}
          selectedValue={viewMode}
        />
        <Controle
          label={"Pressão Atmosférica"}
          icon={iconPresAtmos}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.PRESSAO_ATMOSFERICA}
          selectedValue={viewMode}
        />
        <Controle
          label={"Vento"}
          icon={iconVento}
          iconRotation={45}
          type={"radio"}
          setValue={setViewMode}
          value={ViewMode.VENTO}
          selectedValue={viewMode}
        />
      </Grid>
    </Grid>
  );
};

const FiltroMapa = () => {
  return (
    <Grid container>
      <ControleFiltro />
    </Grid>
  );
};

export default FiltroMapa;
