import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import { MapProvider } from "react-map-gl";

import createTheme from "@mui/material/styles/createTheme";
import MUIProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import useGlobalData from "./providers/useGlobalData";

const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_URL + "/graphql",
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Root = () => {
  const paletteMode = useGlobalData((d) => d.paletteMode);

  const theme = createTheme({
    palette: {
      mode: paletteMode,
      primary: {
        main: "#e67817",
        light: "#ffa84b",
        dark: "#ad4a00",
        contrastText: "#000000",
      },
      secondary: {
        main: "#281570",
        light: "#5a3e9f",
        dark: "#000044",
        contrastText: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: "Inter, Roboto",
      allVariants: { color: paletteMode === "light" ? "#002F4C" : "#EBEEF1" },
      button: {
        letterSpacing: 0.5,
        fontSize: 16,
      },
    },
  });

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <MUIProvider theme={theme}>
            <CssBaseline />
            <App />
          </MUIProvider>
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  );
};

root.render(
  <MapProvider>
    <Root />
  </MapProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(/* console.log */);
