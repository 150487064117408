import React from "react";

import { useQuery, gql } from "@apollo/client";

import useGlobalData /* , { Estacao } */ from "./useGlobalData";
import useDeviceSelectors from "./useDeviceSelector";
import useMediaPreferences from "../util/useMediaPreferences";
import useSidebarBlock from "../util/useSidebarBlock";
import useBreakpoints from "../util/useBreakpoints";
import { useMatch } from "react-router-dom";

const GET_ESTACOES = gql`
  query ListaEstacoes {
    estacoes {
      nome
      codigo
      tipo
      last_read

      localizacao {
        lat
        lng
      }

      nivel_rio
      nivel_rio_historico {
        t_stamp
        nivel
      }

      chuva_001h
      chuva_003h
      chuva_006h
      chuva_012h
      chuva_024h
      chuva_048h
      chuva_072h
      chuva_096h
      chuva_120h
      chuva_144h
      chuva_168h

      temp_atual
      temp_sens
      umidade
      vel_vento
      dir_vento
      pres_atmos

      nivel_montante
      nivel_jusante
      porc_reservatorio
      comp_abertas
      comp_fechadas
    }
  }
`;

const GET_GEOJSON = gql`
  query {
    contornosRegiao
  }
`;

const GlobalDataResolver = () => {
  // const globalData = useGlobalData();
  const setEstacoes = useGlobalData((d) => d.setEstacoes);
  const setRegioes = useGlobalData((d) => d.setRegioes);

  // const updateEstacoes = React.useCallback(
  //   (data: { estacoes: Estacao[] }) => {
  //     console.log(data);
  //     setEstacoes(data.estacoes);
  //   },
  //   [setEstacoes]
  // );

  const estacoesGql = useQuery(GET_ESTACOES, {
    // onError: () => {},
    // onCompleted: updateEstacoes,

    fetchPolicy: "network-only",
    // initialFetchPolicy: "cache-first",
    // nextFetchPolicy: "network-only",
    pollInterval: 15000,
  });

  React.useEffect(() => {
    // console.log(estacoesGql);
    if (estacoesGql.data) {
      setEstacoes(estacoesGql.data.estacoes);
    }
  }, [estacoesGql, setEstacoes]);

  const updateRegioes = React.useCallback(
    (data: { contornosRegiao: string }) => setRegioes(data.contornosRegiao),
    [setRegioes]
  );

  /* const geojsonGql = */ useQuery<{ contornosRegiao: string }>(GET_GEOJSON, {
    onCompleted: updateRegioes,
    fetchPolicy: "cache-first",
  });

  const mobileMode = useGlobalData((d) => d.mobileMode);
  const setMobileMode = useGlobalData((d) => d.setMobileMode);
  const { isMobile, isTablet, isDesktop } = useDeviceSelectors();

  React.useEffect(() => {
    let isMobileMode = isMobile && !isTablet && !isDesktop;
    setMobileMode(isMobileMode);
  }, [isMobile, isTablet, isDesktop, setMobileMode]);

  //#region Configuração de modo escuro
  // Busca as preferências de mídia do dispositivo
  const paletteMode = useGlobalData((d) => d.paletteMode);
  const setPaletteMode = useGlobalData((d) => d.setPaletteMode);

  useMediaPreferences((prefs) => {
    // Se o modo ainda não está definido no dispositivo, define usando o callback
    switch (true) {
      case !paletteMode && prefs.prefersDarkMode:
        setPaletteMode("dark");
        break;
      case !paletteMode && prefs.prefersLightMode:
        setPaletteMode("light");
        break;
    }
  });
  //#endregion

  // Impede o uso da sidebar caso seja mobile e esteja na página da tabela, ou se é desktop e não está no modo compacto
  const { md } = useBreakpoints();
  const isTabela = useMatch("/tabela");
  useSidebarBlock((mobileMode && Boolean(isTabela)) || (!mobileMode && !md));

  // Retorna um elemento vazio, pois não renderiza nada
  return <React.Fragment />;
};

export default GlobalDataResolver;
