import React from "react";

import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import { useMatch } from "react-router-dom";

import LinkButton from "../LinkButton";
import { ControleFiltro } from "../../../routes/mapa/components/interactions/filtros";
import useGlobalData from "../../../providers/useGlobalData";

const SideBar = () => {
  const theme = useTheme();
  const matchMapa = useMatch("/mapa");

  const isSidebarVisible = useGlobalData((d) => d.isSidebarVisible);
  const isSidebarLocked = useGlobalData((d) => d.isSidebarLocked);
  const hideSidebar = useGlobalData((d) => d.hideSidebar);
  const showSidebar = useGlobalData((d) => d.showSidebar);

  const mobileMode = useGlobalData((d) => d.mobileMode);

  return (
    <SwipeableDrawer
      open={isSidebarVisible}
      onOpen={showSidebar}
      onClose={hideSidebar}
      disableSwipeToOpen={isSidebarLocked}
    >
      {/* <Toolbar /> */}

      <Grid
        container
        sx={{
          // width: "100%",
          position: "relative",
          // top: !mobileMode ? "81px" : 0,
          top: !mobileMode ? "64px" : 0,
          maxWidth: "90vw",
          // height: "calc(100vh - 81px)",
          height: "calc(100vh - 64px)",
          // maxHeight: "calc(100vh - 81px)",
          overflowY: "auto",
          padding: "1rem",
          // [theme.breakpoints.between("xs", "sm")]: {
          //   top: !mobileMode ? "62px" : 0,
          //   height: "calc(100vh - 62px)",
          //   maxHeight: "calc(100vh - 62px)",
          // },
        }}
        direction={"column"}
        component={Paper}
        square
        rowGap={"0.5rem"}
        wrap={"nowrap"}
      >
        {!mobileMode && (
          <>
            <Grid item>
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "17px",
                  },
                  [theme.breakpoints.between("md", "lg")]: {
                    fontSize: "18px",
                  },
                  [theme.breakpoints.between("lg", "xl")]: {
                    fontSize: "20px",
                  },

                  fontSize: "21px",
                  fontWeight: 600,
                }}
              >
                Modo de Visualizações
              </Typography>
            </Grid>
            <Grid item>
              <LinkButton to={"/mapa"}>Mapa</LinkButton>
            </Grid>
            <Grid item>
              <LinkButton to={"/tabela"}>Tabela</LinkButton>
            </Grid>
            <Divider />
          </>
        )}
        <Fade in={Boolean(matchMapa)}>
          <Grid item container xs direction={"column"}>
            <ControleFiltro />
          </Grid>
        </Fade>
      </Grid>
    </SwipeableDrawer>
  );
};

export default SideBar;
