import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

type MediaPreferences = {
  prefersDarkMode: boolean;
  prefersLightMode: boolean;
};

const useMediaPreferences = (
  callback?: (preferences: MediaPreferences) => void
): MediaPreferences => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");

  const preferences = React.useMemo(
    () => ({
      prefersDarkMode,
      prefersLightMode,
    }),
    [prefersDarkMode, prefersLightMode]
  );

  React.useEffect(() => {
    // Executa o callback apenas na inicialização
    callback && callback(preferences);
    //eslint-disable-next-line
  }, []);

  return preferences;
};

export default useMediaPreferences;
