import React from "react";

import AppBar from "./AppBar";
import SideBar from "./SideBar";

const Menus = (props: { children: React.ReactNode }) => {
  // Deve verificar se está num ambiente Mobile. Se sim, mover a barra para baixo, e adicionar um menu lateral

  // Prepara a estrutura da aplicação para demonstrar a barra superior ou inferior de acordo com a configuração a ser realizada

  return (
    <React.Fragment>
      <AppBar />
      <SideBar />
      {props.children}
    </React.Fragment>
  );
};

export default Menus;
