import React from "react";
import useGlobalData from "../providers/useGlobalData";

const useSidebarBlock = (condition: boolean) => {
  // Verifica se deve bloquear a barra lateral ou não
  const lockSidebar = useGlobalData((d) => d.lockSidebar);
  const unlockSidebar = useGlobalData((d) => d.unlockSidebar);

  const blockSidebar = React.useMemo(() => condition, [condition]);

  React.useEffect(() => {
    if (blockSidebar) {
      lockSidebar();
    } else {
      unlockSidebar();
    }
  }, [blockSidebar, lockSidebar, unlockSidebar]);

  return;
};

export default useSidebarBlock;
